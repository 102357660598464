import { Link } from "react-router-dom";
import classNames from "classnames";
import Footer from "../../components/footer/Footer";
import ImageLoop from "../../components/image-loop/ImageLoop";
import FluitDrop from "../../assets/images/fluit-drop.png";
import RandomChimp from "../../assets/images/random-chimp.gif";
import { SOCIALS } from "../../constants/socials";
import styles from "./About.module.scss";
import sunset from "./sunset.png"

const About = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img className={styles.club} src={sunset} alt="" />
      </div>
      <div className={styles.divider}>
        <img className={styles.fluit_drop} src={FluitDrop} alt="" />
      </div>
      <div className={styles.content}>
        <div className={styles.section}>
          <span className={styles.title}>FOR THE CHIMPIONS: OUR LEGACY</span>
          <p className={styles.desc}>
            We are a club of 10,000 uniquely generated Chimps on the Cronos
            Chain, each created from over 140 possible traits, making every
            Chimp you adopt truly one-of-a-kind.
            {/* <br className={styles.hide_for_mobile} />{' '} */}
          </p>
          <p className={styles.desc}>
            Since our launch in November 2021, Cronos Chimp Club has been a
            pioneering NFT project on Cronos Chain, bringing together a vibrant
            and creative community of Chimpions.
          </p>
          <p className={styles.desc}>
            Ownership of a Chimp grants you exclusive access to the Cronos Chimp
            Club, where Chimpions have enjoyed community giveaways, merch
            launches, and much more. As a Chimpion, you hold the{" "}
            <Link to="/privacy">IP rights</Link> to the Chimps you own—be
            empowered to show them off however you like!
          </p>
          <p className={styles.desc}>
            As we wind down our official operations in September 2024, we are
            proud to hand over the reins to the community that has made this
            journey so special. This is not just an end, but a new beginning—a
            legacy passed on to all of you who continue to believe in the power
            of community-driven initiatives. The spirit of Cronos Chimp Club
            will live on through your passion and creativity.
          </p>

          <div className={styles.theme_images}>
            {Array.from({ length: 9 }, (_, i) => i * 9 + 1).map(
              (startId, index) => {
                return (
                  <ImageLoop
                    key={startId}
                    className={classNames(styles.theme_img, {
                      [styles.hide_for_tablet]: index > 6,
                      [styles.hide_for_mobile]: index > 4,
                    })}
                    images={[0, 1, 2, 3, 4, 5, 6, 7, 8].map(
                      (step) => `loop/${startId + step}.png`
                    )}
                  />
                );
              }
            )}
            <img className={styles.theme_img} src={RandomChimp} alt="" />
          </div>
        </div>

        <div className={styles.section}>
          <span className={styles.title}>JOIN OUR COMMUNITY</span>
          <p className={styles.desc}>
            Thanks to your support, we will continue to build on this
            tight-knitted community, where magic and bananas happen.
          </p>
          <p className={styles.desc}>
            Though our official marketplace is closed, you can still adopt a
            Chimp through other platforms like{" "}
            <Link to="https://crypto.com/">Crypto.com</Link>, Minted Network or
            Ebisu's Bay, and remain a proud part of the NFT enthusiasts on the
            Cronos Chain.
          </p>
          <p className={styles.desc}>
            Join the community over at our Discord server.
          </p>
          <p className={`${styles.desc} ${styles.desc_highlighted}`}>
            #ChimpsRoamTogether
          </p>

          <div className={styles.socials}>
            {SOCIALS.map(({ to, label, Icon }) => (
              <a
                rel="noreferrer noopener"
                target="_blank"
                key={label}
                href={to}
                className={styles.linkButton}
              >
                {label} <Icon />
              </a>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default About;
