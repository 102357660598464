import styles from "./Statement.module.scss";
import Footer from "../../components/footer/Footer";

export default function Statement() {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>
          Statement: Winding Down of Cronos Chimp Club
        </div>

        <div>
          <p className={styles.content}>
            <b>Date: 30 August 2024</b>
          </p>
          <p className={styles.content}>
            Dear Cronos Chimp Club Community, <br />
            <br /> Today, we share an update with heavy hearts. After much
            reflection and open conversations with our community manager, Eggs,
            we’ve made the difficult decision to sunset Cronos Chimp Club. This
            decision wasn’t easy, but as the project has faced meager
            transaction volumes and rising costs, it’s become clear that winding
            down is the responsible path forward. <br />
            <br /> We’ve had an incredible journey together, filled with
            creativity, collaboration, and memories that we’ll always cherish.
            Our community has been at the heart of Cronos Chimp Club, and it’s
            your passion and support that have driven us every step of the way.
            It’s because of this strong bond that we want to ensure this
            transition is handled with the care and transparency you deserve.
          </p>
          <div className={styles.subtitle}>What's Next:</div>
          <ul className={styles.content}>
            <li>
              <b>Website Continuation: </b> To honor our legacy, the Cronos
              Chimp Club website will remain live for another year, serving as a
              reminder of what we’ve built together and as an archive of all
              that we’ve achieved. However, please expect the following changes
              to the current site:
            </li>
            <li>
              <b>Marketplace Closure (September 27, 2024): </b> On this date,
              the Cronos Chimp Club marketplace will close. However, this
              doesn’t mean the end of trading your beloved Chimps. Moving
              forward, all future transactions can be conducted through verified
              marketplaces:
              <ul>
                <li>
                  <a
                    href="https://crypto.com/nft/collection/492a2ee839a0b3db71f9e33fd563a665?tab=items"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Crypto.com
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    href="https://minted.network/collections/cronos/0x562f021423d75a1636db5be1c4d99bc005ccebfe"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Minted Network
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <b>Account Login Access Disabled (September 27, 2024): </b> You
              will no longer be able to log into your account or access your
              wallet details directly through the Cronos Chimp Club website
              after this date. Please take any necessary actions before the
              closure.
            </li>
            <li>
              <b>Merchandise Availability: </b>We’re happy to share that our
              merchandise will still be available on Shop, while stocks last.
              However, the purchasing process will now redirect you to a Google
              Form. We appreciate your understanding as we make this transition.
            </li>
          </ul>
          <div className={styles.subtitle}>Community and Moving Forward:</div>
          <p className={styles.content}>
            While we’re winding down the official operations, the heart of our
            community will continue to beat strong. Eggs will keep the Discord
            server alive as a fully community-run initiative. Please give him
            your fullest support, as you always have. We know he will carry
            forward the spirit of Cronos Chimp Club with the same passion and
            dedication.
          </p>
          <div className={styles.subtitle}>Snapshot for Holders:</div>
          <p className={styles.content}>
            To recognize those of you who have supported us to the very end, we
            will be taking a snapshot of all current holders on{" "}
            <b> September 13, 2024</b>.
          </p>
          <div className={styles.subtitle}>A Heartfelt Thank You:</div>
          <p className={styles.content}>
            We can’t express enough how grateful we are for the support,
            creativity, and energy you’ve brought to Cronos Chimp Club. This
            decision hasn’t been easy, but it’s one we believe is necessary.
            We’re incredibly proud of what we’ve built together, and we’re
            thankful for the incredible community that has grown around Cronos
            Chimp Club. The memories, the bonds, and the spirit of this
            community will always remain with us.
            <br />
            <br />
            Thank you for being a part of this journey. Your understanding and
            support mean the world to us as we take this step forward.
          </p>
          <span className={styles.content}>
            With gratitude and respect, <br /> Cronos Chimp Club Team
          </span>
        </div>
      </div>
      <Footer />
    </>
  );
}
