import styles from "./Recall.module.scss";
import chimp from "./chimp.svg";
import build from "./build.svg";
import load from "./LoadingImage.svg";
import UnstakeCard from "./UnstakeCard";
import { useEffect } from "react";
import Footer from "../../components/footer/Footer";

export default function Recall() {
  useEffect(() => {}, []);

  return (
    <div className={styles.container}>
      <img src={chimp} alt="" className={styles.left} />
      <img src={build} alt="" className={styles.build} />
      <img src={load} alt="" className={styles.load} />
      <div className={styles.header}>
        <div className={styles.title}>Bring your Chimps back home!</div>
        <div className={styles.card}>
          Chimps have wound down. Please send your Chimps home before{" "}
          <b> 31st December </b> to prevent permanent loss of assets.
        </div>
      </div>
      <UnstakeCard />
      <Footer />
    </div>
  );
}
