import LinkButton from "../../components/button/LinkButton";
import styles from "./ChimpMerch.module.scss";
import image1 from "./item1.png";
import image2 from "./item2.png";

const ChimpMerch = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span>*Special purchase with USDC</span>
        <h1>Purchases now redirect to a Google Form</h1>
      </div>
      <div className={styles.items}>
        <div className={styles.item}>
          <img src={image1} alt="" />
          Merch Box Bundle
        </div>
        <div className={styles.item}>
          <img src={image2} alt="" />
          Steve the Chimp Plushy
        </div>
      </div>
      <a
        href="https://forms.gle/apqYX1E35AX7eXyV6"
        target="_blank"
        rel="noreferrer"
        className={styles.button}
      >
        Fill the form
      </a>
    </div>
  );
};

export default ChimpMerch;
