import { MouseEvent, useCallback, useEffect, useRef } from 'react';
import DarkCloud from '../../assets/images/dark-cloud.png';
import RunChimp from '../../assets/images/run-chimp.png';
import { Arrow } from '../../assets/svgs';
import LinkButton from '../../components/button/LinkButton';
import styles from './Landing.module.scss';

const Landing = () => {
  const videoRef = useRef<any>();

  const handleContextMenu = useCallback((e: MouseEvent) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, [])

  return (
    <div className={styles.container}>
      <video ref={videoRef} className={styles.video} onContextMenu={handleContextMenu} autoPlay loop playsInline>
        <source src='landing.mp4' type='video/mp4' />
      </video>
      <div className={styles.cover} />
      <div className={styles.content}>
        <div className={styles.imgs}>
          <img src={RunChimp} alt="" />
          <img src={DarkCloud} alt="" />
        </div>
        {/* <div className={styles.text}>
          Unravel Ancient Tales
        </div> */}
        <LinkButton to="/statement" className={styles.redirect}>
          Read full statement <Arrow className={styles.arrow} />
        </LinkButton>
      </div>
    </div>
  )
}

export default Landing;