import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Trumpet from "../../assets/images/trumpet.png";
import useAppData from "../../hooks/useAppData";
import styles from "./NotificationBanner.module.scss";

const DEFAULT_BANNER =
  "Chimps Roam Together. Check our socials for latest updates!";

interface BannerEventData {
  message: string;
  startAt: number;
  endAt: number;
}

const NotificationBanner = () => {
  const location = useLocation();

  // const { getLatestBannerData } = useAppData();

  // const [message, setMessage] = useState(DEFAULT_BANNER);

  const [count, setCount] = useState(2);
  const ref = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (ref.current) {
  //       const htmlWidth = document.documentElement.clientWidth;
  //       const itemLength = message.length * 8;

  //       if (htmlWidth && itemLength) {
  //         setCount(Math.ceil(htmlWidth / itemLength));
  //       }
  //     }
  //   };

  //   handleResize();
  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, [message]);

  // useEffect(() => {
  //   const getData = () => {
  //     getLatestBannerData().then((data: BannerEventData) => {
  //       if (data) {
  //         const now = Date.now();
  //         if (now >= data.startAt && now <= data.endAt) {
  //           setMessage(data.message);
  //         }
  //       }
  //     });
  //   };

  //   getData();
  // }, [getLatestBannerData]);

  if (["/admin"].some((path) => location.pathname === path)) return <></>;

  return (
    <div ref={ref} className={styles.component}>
      <div className={styles.content}>
        {[...Array(count).keys()].map((index) => (
          <div key={index} className={styles.alert_item}>
            <img className={styles.alert_img} src={Trumpet} alt="" />
            <span className={styles.alert_txt}>
              Important Announcement: We’re winding down Cronos Chimp Club after
              an incredible journey. Read full statement {""}
              <a className={styles.alert_link} href="/statement">
                here
              </a>.
            </span>
          </div>
        ))}
      </div>
      <div className={styles.content}>
        {[...Array(count).keys()].map((index) => (
          <div key={index} className={styles.alert_item}>
            <img className={styles.alert_img} src={Trumpet} alt="" />
            <span className={styles.alert_txt}>
              Important Announcement: We’re winding down Cronos Chimp Club after
              an incredible journey. Read full statement {""}
              <a className={styles.alert_link} href="/statement">
                here
              </a>.
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotificationBanner;
