import classNames from "classnames";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { SOCIALS } from "../../constants/socials";
import styles from "./Footer.module.scss";

interface Props {
  classname?: string;
}

const NAV_MENU = [
  // {
  //   to: "/",
  //   label: "Marketplace",
  //   isHide: false,
  // },
  {
    to: "/about",
    label: "About",
    isHide: false,
  },
  {
    to: "/terms",
    label: "Terms and Conditions",
    isHide: false,
  },
  {
    to: "/privacy",
    label: "Privacy Policy",
    isHide: false,
  },
  {
    to: "/statement",
    label: "Statement",
    isHide: false,
  }
];

const renderNavbar = () =>
  NAV_MENU.map((item: { to: string; label: string; isHide: boolean }) => (
    <Fragment key={item.label}>
      <Link to={item.to} key={item.to} style={{ textDecoration: "none", color: "inherit" }}>
        {item.label}
      </Link>
      <br />
    </Fragment>
  ));

let content =
  "10,000 uniquely generated Chimps on the Cronos Chain with various traits that celebrate the different aspects of the Cronos ecosystem.";

let header = "CRONOS CHIMP CLUB";

let footer = "© 2024 Cronos Chimp Club. All rights reserved.";

const Footer = ({ classname }: Props) => {
  return (
    <div className={styles.footer_container}>
      <div className={classNames(styles.footer, classname)}>
        <div className={styles.footer_left}>
          <div className={styles.footer_left_text_header}>{header}</div>
          <br />
          <div className={styles.footer_left_text_content}>{content}</div>
          <br />
        </div>
        <div className={styles.footer_right}>
          <div className={styles.footer_right_content}>{renderNavbar()}</div>
          <div className={styles.footer_right_content}>
            {
              SOCIALS.map(({ label, to }) => (
                <Fragment key={label}>
                  <a href={to} rel="noreferrer noopener" target="_blank">
                    {label}
                  </a>
                  <br />
                </Fragment>
              ))
            }
          </div>
        </div>
        <div className={styles.footer_bottom}>
          <div className={styles.footer_left_text_footer}>{footer}</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
