import styles from "./UnstakeCard.module.scss";
import { useWeb3React } from "@web3-react/core";
import { ConnectButton } from "../../components/wallet/Wallet";
import { useState, useMemo } from "react";
import { ethers } from "ethers";
import abi from "../../abi/stakeChimp.json";
import { STAKE_CHIMP_CONTRACT_ADDRESS_UPGRADED } from "../../configs";
import { useChimpay } from "../../providers/ChimpayProvider";

import ProgressButton from "../../components/button/ProgressButton";
import LoadingModal from "./LoadingModal";
export default function UnstakeCard() {
  const { active } = useWeb3React();
  const [loading, setLoading] = useState(false);

  const {
    state: { stakedTokens },
  } = useChimpay();

  const chimpsStaked = useMemo(() => {
    return [...stakedTokens.normal];
  }, [stakedTokens]);

  const handleUnstake = async () => {
    setLoading(true);
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await window.ethereum.request({ method: "eth_requestAccounts" });
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      STAKE_CHIMP_CONTRACT_ADDRESS_UPGRADED,
      abi,
      signer
    );
    const ids = chimpsStaked.map((chimp) => chimp.tokenId);
    const tx = await contract.massUnstake(ids, []);
    await tx.wait();
    setLoading(false);
  };

  return (
    <div className={styles.container}>
      {!active && (
        <>
          <h1 className={styles.title}>
            Connect your wallet to start recalling your Chimps.
          </h1>
          <div className={styles.button}>
            <ConnectButton />
          </div>
        </>
      )}
      {active && chimpsStaked.length > 0 && (
        <div className={styles.card}>
          <h1 className={styles.title}>Your Chimps below are still at work</h1>
          <div className={styles.chimpContainer}>
            {chimpsStaked.map((chimp) => (
              <div key={chimp.tokenId} className={styles.chimp}>
                <img src={chimp.image} alt="" className={styles.image} />
                <span className={styles.subtitle}>
                  Chimp #{chimp.tokenId.toString()}
                </span>
              </div>
            ))}
            {chimpsStaked.map((chimp) => (
              <div key={chimp.tokenId} className={styles.chimp}>
                <img src={chimp.image} alt="" className={styles.image} />
                <span className={styles.subtitle}>
                  Chimp #{chimp.tokenId.toString()}
                </span>
              </div>
            ))}
            {chimpsStaked.map((chimp) => (
              <div key={chimp.tokenId} className={styles.chimp}>
                <img src={chimp.image} alt="" className={styles.image} />
                <span className={styles.subtitle}>
                  Chimp #{chimp.tokenId.toString()}
                </span>
              </div>
            ))}
            {chimpsStaked.map((chimp) => (
              <div key={chimp.tokenId} className={styles.chimp}>
                <img src={chimp.image} alt="" className={styles.image} />
                <span className={styles.subtitle}>
                  Chimp #{chimp.tokenId.toString()}
                </span>
              </div>
            ))}
            {chimpsStaked.map((chimp) => (
              <div key={chimp.tokenId} className={styles.chimp}>
                <img src={chimp.image} alt="" className={styles.image} />
                <span className={styles.subtitle}>
                  Chimp #{chimp.tokenId.toString()}
                </span>
              </div>
            ))}
          </div>
          <ProgressButton classname={styles.button} onClick={handleUnstake}>
            Recall all your Chimps
          </ProgressButton>
        </div>
      )}

      {active && chimpsStaked.length === 0 && (
        <div>You have no staked chimps</div>
      )}
      <LoadingModal open={loading} setIsOpen={setLoading} />
    </div>
  );
}
