import chimp from "./LoadingImage.svg";
import styles from "./LoadingModal.module.scss";

export default function LoadingModal({
  open,
  setIsOpen,
}: {
  open: boolean;
  setIsOpen: (open: boolean) => void;
}) {
  if (!open) return null;
  return (
    <>
      <div className={styles.overlay} onClick={() => setIsOpen(false)} />
      <div className={styles.modal}>
        {/* <button className={styles.button} onClick={() => setIsOpen(false)}>
          X
        </button> */}
        <img src={chimp} alt="" className={styles.image} />
        <div className={styles.content}>
          <h1 className={styles.title}>Recalling your Chimps from work...</h1>
          <p>Almost there! Please approve in wallet.</p>
        </div>
      </div>
    </>
  );
}
